<template>
  <div class="invoice-preview-wrapper">
    <section
      v-for="(piece, index) in data.pieces"
      :key="index"
      class="single_invoice"
    >

      <!-- Alert: No item found -->
      <!--        <b-alert
                  variant="danger"
                  :show="invoiceData === undefined"
              >
                  <h4 class="alert-heading">
                      Error fetching invoice data
                  </h4>
                  <div class="alert-body">
                      No invoice found with this invoice id. Check
                      <b-link
                          class="alert-link"
                          :to="{ name: 'apps-invoice-list'}"
                      >
                          Invoice List
                      </b-link>
                      for other invoices.
                  </div>
              </b-alert>-->
      <b-row
        class="invoice-preview"
      >
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          md="12"
          xl="12"
        >
          <b-card
            class="invoice-preview-card"
            no-body
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0 pt-0">

              <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="">
                    <logo />
                    <!--                  <h3 class="text-primary invoice-logo">
                                        OCS
                                      </h3>-->
                  </div>
                  <!--                <p class="card-text mb-25">
                                    {{
                                      data.consignee_id && data.consignee.addresses.length !== 0 ? data.consignee.addresses[0].address : data.address
                                    }}
                                  </p>-->
                  <!--                                <p class="card-text mb-25">
                                                      San Diego County, CA 91905, USA
                                                  </p>-->
                  <!--                <p class="card-text mb-0">
                                    {{
                                      data.phone1 + ', ' + (data.phone2 ? data.phone2 : '')
                                    }}
                                  </p>-->
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-0">
                  <ShipmentBarCode :value="data.awb" />
                  <!--                <h4 class="invoice-title">
                                    AWB No.
                                    <span class="invoice-number">{{ data.awb }}</span>
                                  </h4>-->
                  <div class="invoice-date-wrapper pt-1 pb-0">
                    <p class="invoice-date-title">
                      Ref No:
                    </p>
                    <p class="invoice-date">
                      {{ data.ref_no }}
                    </p>
                  </div>
                  <!--                <div class="invoice-date-wrapper">
                                    <p class="invoice-date-title">
                                      Last Modified:
                                    </p>
                                    <p class="invoice-date">
                                      {{ data.updated_at | formatDate }}
                                    </p>
                                  </div>-->
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing m-0">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing mb-0 mt-1">

                <!-- Col: Invoice To -->
                <b-col
                  class="p-0"
                  cols="12"
                  xl="6"
                >
                  <h6 class="mb-2">
                    Sender Information:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          <b>Name:</b>
                        </td>
                        <td><span class="font-weight-bold">{{ data.client ? data.client.name : '' }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Account:</b>
                        </td>
                        <td><span class="font-weight-bold">{{ data.client.client_no }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Phone/Mobile:</b>
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ data.client ? data.client.phone : '' }}, {{ data.client ? data.client.mobile : '' }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Address:</b>
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ data.client_address ? data.client_address.address : '' }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>City:</b>
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ data.client.city.title }}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          <b>Country:</b>
                        </td>
                        <td>
                          <span class="font-weight-bold">
                            {{ data.client.city.country.title }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                  cols="12"
                  xl="6"
                >
                  <div>
                    <h6 class="mb-2">
                      Consignee Information:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            <b>Name:</b>
                          </td>
                          <td><span class="font-weight-bold">{{
                            data.consignee_name ? data.consignee_name : (data.consignee ? data.consignee.name : '')
                          }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>Phone:</b>
                          </td>
                          <td colspan="2">
                            {{ data.phone1 ? data.phone1 : '' }}, {{ data.phone2 ? data.phone2 : '' }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>Address:</b>
                          </td>
                          <td colspan="2">{{
                            data.address ? data.address : data.consignee ? data.consignee.addresses[0].name : ''
                          }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>Area:</b>
                          </td>
                          <td>{{ data.area }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <b>City:</b>
                          </td>
                          <td>{{ data.city ? data.city.title : '' }}</td>
                          <td class="pr-1">
                            <b>Country:</b>
                          </td>
                          <td>{{ data.city ? data.city.country.title : '' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  class="p-0 mt-xl-0 mt-2 d-flex"
                  cols="12"
                  xl="12"
                >
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td><b>Serv Type:</b></td>
                        <td>{{ data.service_type.title }}</td>
                        <td><b>Pay Type:</b></td>
                        <td>{{ data.charge_type }}</td>
                        <td><b>COD:</b></td>
                        <td>{{ data.cod_amount }}</td>
                      </tr>
                      <tr>
                        <td><b>Total Weights:</b></td>
                        <td>{{ data.total_weights | roundDecimal }}</td>
                        <td><b>Remark:</b></td>
                        <td>{{ data.remarks }}</td>
                        <td><b>Pieces:</b></td>
                        <td><b>{{ (index + 1) + "/" + data.pieces.length }}</b></td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->
            <table
              id="items_table"
              :key="piece.id"
              class="table table-responsive-sm table-bordered"
            >
              <tr>
                <th>Description</th>
                <th>Origin</th>
                <th>Weight</th>
                <th>Price</th>
              </tr>
              <tr
                v-for="item in piece.items"
                :key="item.id"
              >
                <td>{{ item.description_of_goods }}</td>
                <td>{{ item.country_of_origin }}</td>
                <td>{{ item.weight }}</td>
                <td>{{ item.price }}</td>
              </tr>
              <!--                        <template #cell(taskDescription)="data">
                                          <b-card-text class="font-weight-bold mb-25">
                                              {{ data.item.taskTitle }}
                                          </b-card-text>
                                          <b-card-text class="text-nowrap">
                                              {{ data.item.taskDescription }}
                                          </b-card-text>
                                      </template>-->
            </table>

            <table class="table table-responsive-md table-borderless">
              <tbody>
                <tr>
                  <td class="width-17">
                    <b>Delivered date:</b>
                  </td>
                  <td>...........</td>
                  <td><b>Sign:</b></td>
                  <td>...........</td>
                  <td><b>ID#:</b></td>
                  <td>...........</td>
                </tr>
              </tbody>
            </table>

            <!-- Invoice Description: Total -->
            <!--          <b-card-body class="invoice-padding pb-0">
                        <b-row>

                          &lt;!&ndash; Col: Sales Persion &ndash;&gt;
                          <b-col
                            class="mt-md-0 mt-3"
                            cols="12"
                            md="6"
                            order="2"
                            order-md="1"
                          >
                            &lt;!&ndash;                                <b-card-text class="mb-0">
                                                                <span class="font-weight-bold">Salesperson:</span>
                                                                <span class="ml-75">Alfie Solomons</span>
                                                            </b-card-text>&ndash;&gt;
                          </b-col>

                          &lt;!&ndash; Col: Total &ndash;&gt;
                          &lt;!&ndash;              <b-col
                                          class="mt-md-6 d-flex justify-content-end"
                                          cols="12"
                                          md="6"
                                          order="1"
                                          order-md="2"
                                        >
                                          <div class="invoice-total-wrapper">
                                            <div class="invoice-total-item">
                                              <p class="invoice-total-title">
                                                Subtotal:
                                              </p>
                                              <p class="invoice-total-amount">
                                                {{ data.cod_amount }} LE
                                              </p>
                                            </div>
                                            &lt;!&ndash;                                    <div class="invoice-total-item">
                                                                                    <p class="invoice-total-title">
                                                                                        Discount:
                                                                                    </p>
                                                                                    <p class="invoice-total-amount">
                                                                                        0
                                                                                    </p>
                                                                                </div>&ndash;&gt;
                                            <div class="invoice-total-item">
                                              <p class="invoice-total-title">
                                                Fees:
                                              </p>
                                              <p class="invoice-total-amount">
                                                {{ data.fees }} LE
                                              </p>
                                            </div>
                                            <hr class="my-50">
                                            <div class="invoice-total-item">
                                              <p class="invoice-total-title">
                                                Total:
                                              </p>
                                              <p class="invoice-total-amount">
                                                {{ data.cod_amount + data.fees }} LE
                                              </p>
                                            </div>
                                          </div>
                                        </b-col>&ndash;&gt;
                        </b-row>
                      </b-card-body>-->

            <!-- Spacer -->
            <!--          <hr class="invoice-spacing">-->

            <!-- Note -->
            <!--            <b-card-body class="invoice-padding pt-0">
                          <span class="font-weight-bold"><b>Terms & Condition:</b></span>
                          <span>The above‐ mentioned value declared for customs purpose only. I/We hereby certify that the
                                    information on this invoice is true and correct and that the contents of this shipment are as stated above</span>
                        </b-card-body>-->
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <!--            <b-col
                        cols="12"
                        md="4"
                        xl="3"
                        class="invoice-actions"
                    >
                        <b-card>

                            &lt;!&ndash; Button: Send Invoice &ndash;&gt;
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                v-b-toggle.sidebar-send-invoice
                                variant="primary"
                                class="mb-75"
                                block
                            >
                                Send Invoice
                            </b-button>

                            &lt;!&ndash; Button: DOwnload &ndash;&gt;
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                class="mb-75"
                                block
                            >
                                Download
                            </b-button>

                            &lt;!&ndash; Button: Print &ndash;&gt;
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                class="mb-75"
                                block
                                @click="printInvoice"
                            >
                                Print
                            </b-button>

                            &lt;!&ndash; Button: Edit &ndash;&gt;
                            <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                variant="outline-secondary"
                                class="mb-75"
                                block
                                :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }"
                            >
                                Edit
                            </b-button>

                            &lt;!&ndash; Button: Add Payment &ndash;&gt;
                            <b-button
                                v-b-toggle.sidebar-invoice-add-payment
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="success"
                                class="mb-75"
                                block
                            >
                                Add Payment
                            </b-button>
                        </b-card>
                    </b-col>-->
      </b-row>
      <div class="page-break" />
    </section>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCol, BRow, VBToggle,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import ShipmentBarCode from '@/layouts/components/ShipmentBarCode.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    Logo,
    ShipmentBarCode,
  },
  props: ['data'],
  data() {
    return {
      invoiceDescription: [{
        description_of_goods: this.data.description_of_goods,
        country_of_origin: this.data.country_of_origin,
        weight: this.data.weight,
        pieces: this.data.pieces,
      }],
      fields: ['description_of_goods', 'country_of_origin', 'weight', 'pieces'],
      number_of_print: 2,
    }
  },
  mounted() {

  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
#items_table th td {
  border: 1px solid black;
}

.width-17 {
  width: 17%;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
    height: 50% !important;
  }
  // Global Styles
  body {
    background-color: transparent !important;
    font-size: 18px;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }

    .invoice-preview {
      display: block;
      height: 100%;
    }
  }
}
</style>
